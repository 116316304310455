@import "../../styles/mixins.scss";

.Hero {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  @include desktop {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  h1 {
    @include font-heavy;
    @include font-heading;
  }
}

.Content {
  padding-top: 2rem;

  @include desktop {
    max-width: 52%;
    padding-top: 3.75rem;
  }
}

.FullWidthTitle {
  @include desktop {
    max-width: 100%;
  }
}

.Title {
  display: flex;
  flex-direction: column-reverse;

  p {
    @include font-normal;
    @include font-demi;
    text-decoration: underline;
    margin-bottom: rem(16);
  }

  @include desktop {
    flex-direction: column-reverse;

    h1 {
      margin: 3rem 0 1rem 0;
      @include font-heading-medium;
    }

    p {
      @include font-demi;
      @include font-paragraph;
      text-decoration: none;
      margin-bottom: 0;
    }
  }
}

.Description {
  @include font-book;
  @include font-paragraph-large;
  margin: 1.5rem 0 0 0;

  @include desktop {
    max-width: 42rem;
  }

  &.DescriptionLightFont {
    @include font-book;
  }
}

.CTA {
  display: none;
  margin-top: 2.5rem;

  @include desktop {
    display: block;
  }
}

.Image {
  position: relative;
  align-self: center;

  width: 307px;
  height: 307px;

  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 535px;
    height: 535px;
  }
}
